import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

function SupportBody () {
    const navigate = useNavigate()
    useEffect(()=> {
        
        if(window.$zoho.salesiq){
            const oldWindowZohoObject= window.$zoho.salesiq.ready
            window.$zoho.salesiq.ready=function() {
               window.$zoho.salesiq.floatwindow.visible("show");   
               window.$zoho.salesiq.floatwindow.close(function()
               {
                 navigate("/")
               });
            }

            return () => {
                window.$zoho.salesiq.ready = oldWindowZohoObject;
                window.$zoho.salesiq.floatwindow.close(function() {});
            }
        }
        
    })
    return (
        <></>
    )
}

export default SupportBody