import React, { useState } from "react";
import "./hero.css";
import phone1 from "../../../assets/images/phone1.png";
import Waitlist from "../../../components/waitlist/index.jsx";
import { getMobileOS } from "../../../utils/index.js";
import { ActionButtonAndroid } from "../../../components/action-btn-android/index.jsx";

function Hero() {
  const [open, setOpen] = useState(false);

  const os = getMobileOS();

  function handleWaitlist() {
    if (os === "Android")
      return (window.location.href =
        "https://play.google.com/store/apps/details?id=ng.inpay.app");
    return setOpen(!open);
  }

  return (
    <div className='icontainer pt-5'>
      <br />
      <br />
      <div className='ihero-div mt-1'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-7'>
              <h1 className='ihero-text'>
                Better banking for you and your loved ones
              </h1>
              <div className='pt-1 d-md-none' />

              <p className='ihero-des'>
                iNPAY helps businesses in Africa get paid by{" "}
                <br className='d-none' />
                anyone, anywhere in the world securedly.
              </p>

              <div className='pt-2 d-md-none' />
              <div className='hero-button'>
                {os === "Android" ? (
                  <ActionButtonAndroid handleClick={handleWaitlist} />
                ) : (
                  <button
                    type='button'
                    className='btn hero-ibtn form-control whiteBg'
                    style={{ backgroundColor: "white" }}
                    onClick={handleWaitlist}
                  >
                    <span className='hero-btn-des wl-btn whiteBg'>
                      JOIN THE WAITLIST
                    </span>
                  </button>
                )}
              </div>

              <div className='pt-4 d-none d-md-block' />

              <Waitlist
                setClicked={handleWaitlist}
                clicked={open === true ? "true" : "false"}
              />
            </div>

            <div className='col-sm-12 col-md-5'>
              <div className='pt-4 d-md-none' />
              <img src={phone1} className='phn' alt='logo' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
