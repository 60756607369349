import React, { useState } from "react";
import inpay_special_phone_mobile from "../../../assets/images/inpay-special-phone-mobile.png";
import inpay_special_phone from "../../../assets/images/inpay-special-phone.svg";
import "./solution_two.css";
import Waitlist from "../../../components/waitlist";
import { getMobileOS } from "../../../utils";
import { ActionButtonAndroid } from "../../../components/action-btn-android";

function SolutionTwo() {
  const [open, setOpen] = useState(false);

  const os = getMobileOS();

  function handleWaitlist() {
    if (os === "Android")
      return (window.location.href =
        "https://play.google.com/store/apps/details?id=ng.inpay.app");
    return setOpen(!open);
  }

  return (
    <div className='container pt-5'>
      <div className='pt-2'></div>
      <center>
        <div className='inpay-special d-md-none'>
          <div className='inpay-special-text-div'>
            <p className='inpay-special-text-title'> iNPAY's Special</p>
            <p className='inpay-special-text-subtitle'>
              One Solution for all your Cross-Border Payments.
            </p>
            <p className='inpay-special-text-body'>
              Download the iNPAY app and enjoy the convenience of making payment
              and making informed decisions right at your fingertips.
            </p>
          </div>

          <div className='inpay-special-text-button'>
            {os === "Android" ? (
              <ActionButtonAndroid handleClick={handleWaitlist} />
            ) : (
              <button
                type='button'
                className='btn hero-ibtn form-control whiteBg'
                style={{ backgroundColor: "white" }}
                onClick={handleWaitlist}
              >
                <span className='hero-btn-des wl-btn whiteBg'>
                  JOIN THE WAITLIST
                </span>
              </button>
            )}
            <Waitlist
              setClicked={handleWaitlist}
              clicked={open === true ? "true" : "false"}
            />
          </div>
          <div className='image-div'>
            <img
              className='image-div phone-image'
              src={inpay_special_phone_mobile}
              alt=''
            />
          </div>
        </div>
      </center>

      <div className='inpay-special d-none d-md-block'>
        <div className='inpay-special-text-div'>
          <p className='inpay-special-text-title'> iNPAY's Special</p>
          <p className='inpay-special-text-subtitle'>
            One Solution for all your Cross-Border Payments.
          </p>
          <p className='inpay-special-text-body'>
            Download the iNPAY app and enjoy the convenience of making payment
            and making informed decisions right at your fingertips.
          </p>
        </div>

        <img className='phone-image' src={inpay_special_phone} alt='' />

        <div className='inpay-special-text-button'>
          <div className='pt-4 d-none d-md-block' />

          {os === "Android" ? (
            <ActionButtonAndroid handleClick={handleWaitlist} />
          ) : (
            <button
              type='button'
              className='btn hero-ibtn form-control whiteBg'
              style={{ backgroundColor: "white" }}
              onClick={handleWaitlist}
            >
              <span className='hero-btn-des wl-btn whiteBg'>
                JOIN THE WAITLIST
              </span>
            </button>
          )}
          <Waitlist
            setClicked={handleWaitlist}
            clicked={open === true ? "true" : "false"}
          />
        </div>
      </div>
    </div>
  );
}

export default SolutionTwo;
