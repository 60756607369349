import './header.css';
import HomePageNavbar from '../../../components/navbar';


function Header () {
    return (
        <div>
            <br/>
            <br/>
            <br/>
            <div className="d-md-none">
                <HomePageNavbar/>
            </div>
            <center>
                <div className='p-3' />
                <div className='header-subtitle'>Inpay Support</div>
            </center>
            <br/>
        </div>
    )
}

export default Header